<ng-container *ngIf="messages && messages.length > 0">
  <div class="alert-container">
    <ng-container *ngFor="let message of messages">
      <div class="alert bg-{{ message.severity }}">
        <span data-notify="icon" class="fa fa-{{ message.icon }} text-{{ message.severity }}"></span>
        <span data-notify="title">{{ message.title | translate }}</span>
        <span data-notify="message" [innerHTML]="message.message | translate"></span>
      </div>
    </ng-container>
  </div>
</ng-container>
